import React from 'react'
import './index.css'

const styleImage = {
    background: 'url("images/supply_chain.jpg")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
}

export default () => 
    <section className='SupplyChain' id='supplychain'>
        <div 
            className='SupplyChain__Left' 
            title='Smart sensor automation for improved supply chain orchestration'
            style={styleImage}>
        </div>
        <div className='SupplyChain__Right'>
            <h3>Supply Chain Challenges</h3>
            <p>
                Outdated processes and unsynchronised data undermine your entire operation. Countless modern companies are still faced with supply chain inefficiencies that significantly affect their bottom line. These include disruptions in manufacturing, transportation delays and outdated inventory to name a few.
            </p>
            <h3>Supply Chain Solutions</h3>
            <p>
                To mitigate the risk that compromises your success we have introduced a revolutionary solution that eliminates common human error, while speeding up your processes and amalgamating Live data through a Unique Global ID for improved decision making.
            </p>
        </div>
    </section>