import './utils/polyfills'
import 'intersection-observer'
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Switch, Route } from 'react-router-dom'
import { createStore } from 'react-hookstore'
import monitoring from '@taghub/taghub-monitoring'
import reportWebVitals from './reportWebVitals'
import Home from './Home'
import Terms from './Terms'
import Privacy from './Privacy'
import NotFound from './NotFound'
import Header from './Header'
import { constantsToStyle } from './constants'
import screwIE from './utils/screwIE'
import pkg from '../package.json'
import './index.css'

monitoring.init({
    key: 'd1bca48b4e3648d9ab7155b5d4463824',
    project: 19,
    version: pkg.version
})

screwIE()

createStore('nameVisible', true)
createStore('logging', false)
createStore('contactMessage', '')

const style = constantsToStyle()

const App = () => {
  return (
    <div className='App' style={style}>
      <HashRouter>
        <Header />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/top' component={Home}/>
          <Route path='/about' component={Home}/>
          <Route path='/technology' component={Home}/>
          <Route path='/products' component={Home}/>
          <Route path='/benefits' component={Home}/>
          <Route path='/contact' component={Home}/>
          <Route path='/contact-form' component={Home}/>
          <Route path='/terms' component={Terms} />
          <Route path='/privacy' component={Privacy} />
          <Route component={NotFound} />
        </Switch>
      </HashRouter>
    </div>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>, 
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
