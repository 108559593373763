import React       from 'react'
import Top         from './Top'
import About       from './About'
import SupplyChain from './SupplyChain'
import Sharing     from './Sharing'
import Technology  from './Technology'
import Products    from './Products'
import Benefits    from './Benefits'
import Contact     from './Contact'
import Footer      from './Footer'
import './index.css'

export default () => 
    <div className='Home'>
        <Top />
        <About />
        <SupplyChain />
        <Technology />
        <Sharing />
        <Products />
        <Benefits />
        <Contact />
        <Footer />
    </div>