import './index.css'

export default function Terms() {
  return (
    <div className='Terms'>
      <h2>Terms and Conditions</h2>
      
      <h3>Section 1: Abstract</h3>
      <p>
        This document contains the overall policy in use by Smart Management AS with regard to the retention of Data for use in Smart Management AS’s service(s) and product(s) offering(s).  It also outlines the fundamental agreement(s) required between Smart Management AS and its Client(s) in order for the service(s) and product(s) to properly function per the Service Agreement(s) between Smart Management AS and its Client(s)
      </p>


      <h3>Section 2: Definitions</h3>
      <p>
        “Smart”, “SmartM”, “Smart Management” refers to Smart Management AS, a limited liability company registered in the country of Norway with organization number  989 999 060.

        “Client” refers to any person or company paying a fee to Smart Management in return for products and services.

        “Clients” refers to the plural of “Client” as defined in this document.

        “Data” refers to information contained in a Database.

        “Database” refers to a software program, programming language, and related software utilities used for the purpose of storing Data for archiving as well as for use within software applications.

      </p>


      <h3>Section 3: Scope</h3>
      <p>
        By this document SmartM describes in reasonable technical detail the creation, management, maintenance, use, storage, security, and deletion policies and practices for Data used in and throughout its software products and offerings on behalf of Clients.  Technical details are kept to a minimum so as to protect SmartM’s intellectual property, but with enough description that a reasonable person can understand at a high level the data services and practices that SmartM uses and provides.
      </p>


      <h3>Section 4: Creation and Import</h3>
      <p>
        SmartM provides Clients with the possibility to take their existing Data from external systems and products and Create and/or Import it in services and products provided by SmartM.  External Importation of Data into Smart Management’s services and products is determined by the Service Agreement entered into upon starting the business relationship between SmartM and their Client(s).  Such operations are deemed “Custom” and are not to be considered intrinsic to the Service Agreement and Costs involved in starting the business relationship with Smart Management.
      </p>


      <h3>Section 5: Maintenance</h3>
      <p>
        Smart Management uses several monitoring and alert tools in order to track the state of Data and the servers on which that Data resides.  

        A periodic check of all infrastructure is performed as determined by the expertise of the Technical Team in order to maintain the health and proper operation of all of Smart Management’s products and services.  

        Maintenance includes but is not limited to:
        <ol>
          <li>System level updates, bug fixes, and patching</li>
          <li>Elastic Block Store expansion, compression, creation, and connection</li>
          <li>Database migration, backup, compression, and performance monitoring</li>
          <li>System Log parsing, cleanup, migration, and removal</li>
          <li>Software test writing, verifying, and execution</li>
          <li>Research, Development, and Implementation of new technologies as needed</li>
        </ol>
        Smart Management takes all precautions necessary to maintain the Service Level Agreements specified for each client at the start of the business relationship.
      </p>


      <h3>Section 6: Backups of Client Data</h3>
      <p>
        Smart Management executes automated backup routines for all Databases.  These backups are stored in several locations on SmartM’s infrastructure.  The backup files stored are kept for five (5) days and then deleted.
      </p>


      <h3>Section 7: Security</h3>
      <p>
        Smart Management takes security very seriously.  The Technical Team at Smart Management maintains a constant watch on industry standard and industry leading resources for possible security bugs, breaches, and exploits discovered by the Software community for all software tools employed by the technical team.  Due to this focus on security, the Smart Management Technical Team maintains an internal goal of 12 hours response time from announcement of the security bug, breach, and/or exploit to the closing, fixing, and /or patching of the security bug, breach, and/or exploit.
      </p>
      <p>
        All Smart Management Technical Team members maintain proficiency and expertise in maintaining and ensuring security from the Linux Kernel level all the way up to the client-side/browser level.  The Technical Team’s security practices are constantly under scrutiny and always being revised so as to try and stay as up-to-date as possible with possible threats.
      </p>
      <p>
        While it is impossible for any team or organization to guarantee one hundred percent (100%) security of Data and infrastructure, the Smart Management Technical Team maintains constant situational awareness and is always testing and searching for possible ways to improve upon existing and new security technologies and practices.
      </p>


      <h3>Section 8: Data Ownership</h3>
      <p>
        SmartM maintains the above outlined responsibilities on behalf of all clients using SmartM’s products.  However, all client data in SmartM’s products is the property of and owned by each respective client.  SmartM makes no claims to client data ownership and operates solely within the above described sections and scope as well as per each client’s service agreement.
      </p>


      <h3>Section 9: Client Requests for Data</h3>
      <p>
        Clients may request, in writing, that their data be exported to an electronic format (Database Dump) and placed in a compressed archive for remote retrieval/download by client representatives via a URL pointing to a secured location on SmartM’s infrastructure.  Clients are required to specify in their request the following:
        <ol>
          <li>Client Name/Company Name</li>
          <li>Date of request</li>
          <li>Client Representative(s) who is/are to receive the exported data URL</li>
          <li>Scope of data or “ALL”</li>
        </ol>
      </p>
      <p>
        Upon receiving such a request, SmartM agrees to provide a response acknowledging the request for data as well as to provide a quote for a reasonable possible timeframe of when the data will be made available and an estimate of the costs involved.  Due to varying data contents and sizes, any quote of time should be considered an estimate and not a definitive date of availability.
      </p>
      <p>
        By making such requests, clients agree to be charged an hourly rate for the time it takes SmartM to query, export, verify, compress, and archive their requested data.
      </p>


      <h3>Section 10: Termination of Provider/Client Relationship</h3>
      <p>
        In the event of a termination of service agreement between SmartM and its client(s), SmartM will retain client data for a period of up to three (3) calendar months from the date of the termination of the business relationship.  Clients wishing to terminate their business agreements with SmartM will have said three (3) calendar month period to provide a written request, as described in Section 9 of this document, to retrieve their data.  On the day immediately following the three (3) calendar month retention period, SmartM and its technical team will PERMANENTLY DELETE all client data from its product(s) database(s) and infrastructure.  This action will make data recovery impossible and ensure that no data is retained for a client not in a business relationship with SmartM.
      </p>
    </div>
  )
}