import React, { useEffect } from 'react'
import classnames from 'classnames'
import { useInView } from 'react-intersection-observer'
import { useStore } from 'react-hookstore'
//import Login from './Login'
import { HEADER_HEIGHT, SIGNUP_MESSAGE } from '../../constants'
import './index.css'

export default function Top() {
    const [ref, inView] = useInView({ rootMargin: `-${HEADER_HEIGHT}px` })
    const [ , setNameVisible ] = useStore('nameVisible')
    const [ logging, setLogging ] = useStore('logging')
    const [ , setContactMessage ] = useStore('contactMessage')
    /*const signUp = () => {
        setContactMessage(SIGNUP_MESSAGE)
        window.location.hash = ''
        window.location.hash = 'contact-form'
    }*/
    useEffect(() => {
        setNameVisible(inView)
    })
    const classes = classnames({ Top: true, logging })
    const styleImage = {
        background: 'url("images/map.png")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    }
    return (
        <section className={classes} id='top'>
            <div className='Top__Form'>
                <img ref={ref} src='images/smartid.png' alt="smartid" />
                <h3>Connects your Supply Chain</h3>
            </div>
            <div 
                className='Top__Image' 
                title='Gain a strategic view of assets and locations in your supply chain network'
                style={styleImage}>
            </div>
        </section>
    )
}
