
export const HEADER_HEIGHT = 64

export const constantsToStyle = () => ({
    '--header-height': `${HEADER_HEIGHT}px`
})

export const DEMO_MESSAGE = `Hi! 👋

I would like to request a demo of TagHub. 🚀

Please contact me to this e-mail.

Thanks 🙂`

export const SIGNUP_MESSAGE = `Hi! 👋

I would like to sign-up to TagHub. 📝

Please contact me to this e-mail.

Thanks 🙂`