import './index.css'

export default function Privacy() {
  return (
    <div className='Privacy'>
      <h2>Privacy Statement</h2>
      
      <h3>Scope</h3>
      <p>
        This Privacy Statement applies to all activities in Smart Management AS, and includes all work and all activities performed by us in our own premises (including home offices), all our customers' premises, and all other contexts and locations where we represent ourselves, or represent our customers.
        The privacy statement also includes all interaction with customers, prospects, partners, suppliers and other stakeholders, regardless of method or the form of communication.
      </p>
      
      <h3>Personal data</h3>
      <p>
        Smart Management AS only stores basic information about contact persons in the companies we are connected to. This information includes:
        <ul>
          <li>Name</li>
          <li>Position in the company</li>
          <li>E-mail / telephone used in the employment relationship.</li>
        </ul>
        The information is collected as a result of professional contact with company representatives in these contexts:
        <ul>
          <li>Contact in connection with B2B marketing and sales</li>
          <li>Companies that are our customers or suppliers</li>
          <li>Collaborating companies</li>
          <li>Participants in events under the auspices of Smart Management AS or others</li>
        </ul>
        The purpose of this information is to maintain business relationships, and to inform about products, services and offers to interested companies.
      </p>

      <h3>The rights of the data subjects</h3>
      <p>
        <ul>
          <li>Access to registered information, correction and deletion of data will be performed with a request to the general manager</li>
          <li>Smart Management AS has no routines for profiling or automated processing of personal data</li>
          <li>We never disclose personal information to third parties</li>
        </ul>
      </p>

      <h3>Our products</h3>
      <p>
        Smart Management AS can gain access to the personal information that the data controllers have implemented in the systems provided by us. Data Processor agreements are therefore entered into with the companies that use our products. The agreements shall clarify and delimit the responsibility between the parties, and ensure the necessary data security for the registered.
        The systems we deliver use little personal information, and under no circumstances require information that is considered sensitive in the legislation. 
        Information that is processed is limited to include the name, position and the e-mail of the registered employees that are introduced by the companies' that implement the systems. 
      </p>

      <h3>Duty of confidentiality</h3>
      <p>
        All employees of Smart Management AS are subject to a duty of confidentiality within the scope of this declaration. 
      </p>
    </div>
  )
}