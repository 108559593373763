import React, { useState } from 'react'
import { useStore } from 'react-hookstore'
import useOnClickOutside from 'use-onclickoutside'
import { useSpring, animated } from 'react-spring'
import useWindowSize from '../utils/useWindowSize'
import { ReactComponent as Hamburger } from '@taghub/taghub-styling/graphics/menu.svg'
import { DEMO_MESSAGE } from '../constants'
import './index.css'

const MenuItems = () => {
    const [ , setContactMessage ] = useStore('contactMessage')
    const onDemoClick = () => setContactMessage(DEMO_MESSAGE)
    return (
        <React.Fragment>
            <a href='#about'>About Us</a>
            <a href='#technology'>Technology</a>
            <a href='#products'>Products</a>
            <a href='#benefits'>Benefits</a>
            <a href='#contact'>Contact Us</a>
            <a href='#contact-form' className='MenuItem__Demo' onClick={onDemoClick}>
                Demo
            </a>
        </React.Fragment>
    )
}

const Menu = () => {
    const [menuVisible, setMenuVisible] = useState(false)
    const style = useSpring({ height: menuVisible ? 320 : 240 })
    const onMenuClick = () => setMenuVisible(!menuVisible)
    const onMenuClose = () => setMenuVisible(false)
    const ref = React.useRef(null)
    useOnClickOutside(ref, onMenuClose)
    return (
        <div className='Menu' ref={ref}>
            <Hamburger onClick={onMenuClick} />
            {
                menuVisible && 
                <animated.div className='MenuItems' style={style}>
                    <MenuItems />
                </animated.div>
            }
        </div>
    )
}

const Logo   = () => <img src='images/logo.png' alt="logo" />
const Taghub = () => <img src='images/smartid.png' alt="smartid" />

export default function Header() {
    const size = useWindowSize()
    const [ nameVisible ] = useStore('nameVisible')
    const showName = !nameVisible
    const widthToShowMenu = showName ? 800 : 655
    return (
        <header>
            <a className='HeaderLeft' href='#top'>
                <Logo />
                {
                    showName && 
                    <Taghub />
                }
            </a>
            <div className='HeaderRight'>
            {
                size.width > widthToShowMenu
                ? <MenuItems />
                : <Menu />
            }
            </div>
        </header>
    )
}
