import React    from 'react'
import { ReactComponent as Facebook } from '@taghub/taghub-styling/graphics/social/facebook.svg'
import { ReactComponent as Twitter } from '@taghub/taghub-styling/graphics/social/twitter.svg'
import { ReactComponent as GitHub } from '@taghub/taghub-styling/graphics/social/github.svg'
import './index.css'

const TERMS_URL = 'https://docs.google.com/document/d/1Hsfj7wekME3kPCu4aP69fHDtx6Z6zUivT2s3sfrbFH8/edit?usp=sharing'
const PRIVACY_URL = 'https://docs.google.com/document/d/1ne4ZUTRGiW8V8rcnnRsnjy76izaTc38bKLErbQeUbqo/edit?usp=sharing'
// const TERMS_URL = '#terms'
// const PRIVACY_URL = '#privacy'

export default function Footer() {
  return (
    <footer className='Footer'>
      <div className='Footer__Left'>
      </div>
      <div className='Footer__Right'>
        <span>
          <a href={PRIVACY_URL} target='_blank' rel='noreferrer'>Privacy</a> | <a href={TERMS_URL} target='_blank' rel='noreferrer'>Terms</a>
        </span>
        <span>
          © { (new Date()).getFullYear() } Smart ID
        </span>
      </div>
    </footer>
  )
}
/*
<a href='https://www.facebook.com/smartmnorway' target='_blank' rel="noopener noreferrer"><Facebook /></a>
        <a href='https://twitter.com/taghub' target='_blank' rel="noopener noreferrer"><Twitter /></a>
        <a href='https://github.com/taghubnet' target='_blank' rel="noopener noreferrer"><GitHub /></a>
*/