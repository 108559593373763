import React from 'react'
import './index.css'


const styleImage = {
    background: 'url("images/sharing.png") no-repeat scroll right center',
    backgroundSize: 'cover',
    backgroundOrigin: 'content-box'
}

const styleImageBackground = {
    background: 'url("images/bee-network.png")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center'
}

export default () => 
    <section className='Sharing' id='sharing'>
        <h2>Sharing</h2>
        <div className='Sharing__Text'>
            <p>
                The powerful, cloud-based TagHub technology provides a secure and collaborative data exchange network for various departments and businesses across the supply chain to access real-time operational information. Companies that use multiple stand-alone SCM systems can take advantage of implementing and integrating an ERP solution (or vice versa) that offers cross-platform insight into important data, resulting in the delivery of superior supply chain processes and real competitive advantage.
            </p>
            <p>
                In an ecosystem of integrated software solutions, businesses that depend on inter-departmental and multi-organisational communication, will greatly benefit from the extra control and flexibility to connect and operate through TagHub’s best in class set of APIs. The end-to-end visibility that this sharing environment presents, leads to streamlined workflows that have a substantial impact on the overall efficiency of contemporary supply chain models.
            </p>
        </div>
        <div className='Sharing__Overview' style={styleImageBackground}>
            <div className='Sharing__Overview__Text'>
                <h3>TagHub overview</h3>
                <ul>
                    <li>Warehouse</li>
                    <li>Shipment</li>
                    <li>Inventory</li>
                    <li>Onshore and offshore control</li>
                    <li>Online planning tool</li>
                </ul>
                <h3>Real-time data</h3>
                <ul>
                    <li>Keep track of your goods and equipment</li>
                    <li>See status directly from suppliers</li>
                </ul>
                <h3>View assets</h3>
                <ul>
                    <li>Always up-to-date</li>
                    <li>Information is available</li>
                </ul>
                <h3>Supplier</h3>
                <ul>
                    <li>Warehouse</li>
                    <li>Production</li>
                    <li>Sharing</li>
                    <li>Rental</li>
                    <li>Inspection</li>
                </ul>
            </div>
            <div 
                className='Sharing__Overview__Image' 
                title='Share up-to-date operational information'
                style={styleImage}>
            </div>
        </div>
    </section>